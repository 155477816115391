.summary-information-container {
    padding-left: 15px;
    padding-right: 15px;
    background: #F5F5F5;
    min-height: 373px;
    position: relative;
    .loader-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 50;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(#fff, 0.1);
    }
    .main-container {
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        padding-bottom: 16px;
        .sub-header {
            padding-bottom: 12px;
        }
        .MuiPaper-root {
            div {
                overflow-x: inherit !important;
            }
        }
        .hint-text {
            font-size: 13px;
            line-height: 20px;
        }
        .MuiTableCell-root {
            height: 47px;
        }
        .MuiTableRow-root {
            .summary-information-opened-row-container {
                .button {
                    display: none;
                }
            }

            &:hover {
                .summary-information-opened-row-container {
                    .text {
                       display: none;
                    }
                    .button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
    .monitoring-slider-header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        & form {
            flex: 1;

            & > div {
                justify-content: flex-end;
            }
        }
    }
}
