
@mixin text-h1() {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.01em;
}

@mixin text-h2() {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
}

@mixin text-h3() {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.01em;
}

@mixin text-h4() {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

@mixin text-body() {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@mixin text-body-bold() {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

@mixin text-caption() {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}

@mixin text-caption-bold() {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}

@mixin text-button() {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

@mixin text-input() {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@mixin text-label() {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
