$primary-color-main: #E64A19;
$primary-color-light: #FF5722;

$text-color-primary: #000000;
$text-color-secondary: #343434;
$text-color-hint: #5C6C7F;

$neutral-dark-color: #212121;
$neutral-main-color: #616161;
$neutral-light-color: #9E9E9E;
$neutral-background-color: #E5E5E5;
$neutral-ultra-light-color: #D6D6D6;
$neutral-white-color: #F5F5F5;

$system-error-color: #F20808;
$system-success-color: #39CC59;
$system-warning-color: #FFDC00;

$diagrams-light-color: #DF8B71;
$diagrams-medium-color: #CC3100;
$diagrams-dark-color: #6B1A00;

$left-menu-collapsed-width: 64px;
$left-menu-right-margin: 36px;
$top-bar-height: 56px;
