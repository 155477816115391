.multiselect-dropdown {
  position: relative;
  border: 1px solid #F5F5F5;
  border-radius: 4px;
  background-color: #616161;
  & .multiselect-dropdown__value {
    height: 40px;
    background-color: #616161;
  }
  &--disabled {
    pointer-events: none;
  }
  &:focus-within {
    border: 1px solid #FF5722;
  }
  .MuiIconButton-root {
    color: #E5E5E5;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #FF5722;
  }
  ::placeholder {
    color: #F5F5F5;
  }
  &__input {
    background-color: rgba(grey, 0.03);
    height: 40px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 20px;
    color: #F5F5F5;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    transition: .3s;
    display: flex;
    align-items: center;
    .multiselect-dropdown--disabled & {
      color: rgba(grey, 0.36);
    }
    &__icon-wrapper {
      position: absolute;
      height: 40px;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 6px;
      .dropdownArrow {
        color: #FF5722;
      };
      .dropdownArrowRotate {
        transform: rotate(180deg);
      }
      svg {
        fill: #FF5722;
        cursor: pointer;
        transition: .3s;
      }
    }
    &__icon {
      margin-left: 2px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      &-clear {
        display: flex;
        svg {
          color: #9E9E9E;
        }
      }
    }
    &__helper {
      color: rgba(black, 0.54);
      font-size: 12px;
      line-height: 16px;
      .multiselect-dropdown--error & {
        color: red;
      }
      .multiselect-dropdown--disabled & {
        color: rgba(grey, 0.36);
      }
    }
    &__search {
      border: none;
      background-color: transparent;
      outline: none;
      font-size: 14px;
      line-height: 20px;
      color: #F5F5F5;
    }
    &__placeholder {
      position: absolute;
      left: 18px;
      right: 40px;
      pointer-events: none;
      z-index: 0;
      line-height: 28px;
    }
    & .top-label {
      position: absolute;
      color: white;
      top: -11px;
      background-color: #616161;
    };
    & .top-label-focused {
      color: #FF5722;
    }
  }
  .MuiPaper-root {
    & .multiselect-dropdown__dropdown {
      border-radius: 24px;
    }
  }
  &__dropdown {
    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    z-index: 11;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    &:not(.active) {
      display: none;
    }
    .multiselect-dropdown & .MuiPaper-root {
      box-shadow: 10px 10px 20px black, 0 6px 6px rgba(black, 0.26);
    }
    &__list {
      background-color: #616161;
      position: relative;
      & > div {
        scrollbar-color: #ffffff transparent;
        scrollbar-width: thin;
        padding-right: 4px;

        /* webkit scrollbar styles */
        &::-webkit-scrollbar {
          width: 2px;
          background-image: linear-gradient(to top, rgba(lighten(#ffffff, 15%), 0.4), rgba(#ffffff, 0.4), rgba(lighten(#ffffff, 15%), 0.4));
        }

        &::-webkit-scrollbar-thumb {
          background: #ffffff;
          border-radius: 2px;
        }
      }
    }
  }
  &__value {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 11px 6px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #F5F5F5;
    transition: .3s;
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
      background-color: #212121;
    }
    &:focus, &--focused {
      background-color: #343434;
      outline: none;
    }
    .custom-checkbox {
      margin-right: 8px;
      .MuiFormControlLabel-root {
        margin: 0;
      }
      .MuiCheckbox-root {
        padding: 0;
      }
    }
    &__footer {
      display: none;
      padding: 13px 16px;
      text-decoration: none;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: yellow;
      user-select: none;
    }
  }
  &__selected {
    display: flex;
    align-items: center;
    &__value {
      height: 26px;
      font-size: 14px;
      line-height: 18px;
      color: #F5F5F5;
      font-weight: 600;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-right: 4px;
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      .text-elipsis {
        word-break: break-all;
      }
      .multiselect-dropdown--disabled & {
        opacity: 0.5;
      }
      &__delete {
        &.MuiIconButton-root {
          margin-left: 5px;
          padding: 0;
          width: 12px;
          height: 12px;
          fill: #FF5722;
          .multiselect-dropdown--disabled & {
            color: lightgray;
          }
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}
