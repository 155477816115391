.monitoring-slider-header {
    padding: 16px;
    margin-left: -16px;
    margin-right: -16px;
    border-bottom: 1px solid #A8A8A8;
    .slider-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        svg {
            &:hover {
                cursor: pointer;
                border-radius: 20px;
                background: #E5E5E5;
            }
        }
    }
    .text {
        user-select: none;
        font-size: 14px;
        text-transform: uppercase;
        &.bold {
            font-weight: 600;
            line-height: 20px;
        }
    }
}