.MuiTableRow-root {
  .cell-fixed {
    &--right {
      right: 0;
    }
  }
}

.error-icon {
  margin-top: 6px;
}

.tooltip {
  white-space: pre-line;
}
