.error-detalization-container {
    padding-left: 15px;
    padding-right: 15px;
    background: #F5F5F5;
    min-height: 373px;
    .main-container {
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        padding-bottom: 16px;
        .MuiPaper-root {
            div {
                overflow-x: inherit !important;
            }
        }
        .MuiTableCell-root {
            height: 47px;
        }
        .MuiTableRow-root {
            .summary-information-opened-row-container {
                .button {
                    display: none;
                }
            }
    
            &:hover {
                .summary-information-opened-row-container {
                    .text {
                       display: none;
                    }
                    .button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}