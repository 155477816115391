@import 'src/styles/variables';
@import 'src/styles/typography';

@import 'src/styles/spacing';

@font-face {
    font-family: 'SF Pro Display';
    src: url(../assets/fonts/SFProDisplay-Regular.ttf)  format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Display';
    src: url(../assets/fonts/SFProDisplay-Semibold.ttf)  format('truetype');
    font-weight: 500;
    font-style: normal;
}

* { box-sizing: border-box; }

html, body, #root {
    height: 100%;
}

body {
    font-family: 'SF Pro Display', sans-serif;
    color: $text-color-primary;
    background-color: $neutral-background-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;

    @include text-body();
}

.root-element {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
}
