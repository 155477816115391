.breadcrumb-main-container {
  margin-bottom: -10px;
}

.MuiTypography-root .breadcrumbs-item {
  font-size: 13px;
  letter-spacing: 0;
  cursor: pointer;
}

.MuiBreadcrumbs-separator {
  margin: 0;
}

.MuiTypography-root.breadcrumbs-item.MuiTypography-caption {
  color: #5C6C7F;
  letter-spacing: 0;

  &:hover {
    color: #343434;
    cursor: pointer;
  }

  .last-item-color {
    &:hover {
      color: #18191F;
    }
  }
}

.breadcrumbs-stories-links-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
}

.breadcrumb-arrow {
  color: #5C6C7F;
}
