.text-elipsis {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: normal;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-height: 1.4em;
  }
  &__lines {
    &-2 {
      -webkit-line-clamp: 2;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-height: 2.8em;
      }
    }
  }
}
