@import 'src/styles/variables';

.top-bar {
    position: relative;
    z-index: 12;

    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

    .app-bar {
        height: $top-bar-height;
        padding-left: $left-menu-collapsed-width;
    }

    header {
        background-color: $neutral-white-color;
        color: $text-color-secondary;
        position: relative;
    }

    .profile {
        margin-left: auto;
        padding-left: 15px;

        .photo {
            border-radius: 50%;
            overflow: hidden;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: 24px;
            }

            .progress {
                height: 24px;
                width: 24px;
            }
        }
    }

    .MuiToolbar-regular {
        min-height: $top-bar-height;
    }

    .MuiToolbar-gutters {
        padding-left: 0;
    }
}
