.waiting-loader {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 10003;

    .waiting-loader-back {
        background-color: #aaa;
        height: 100%;
        width: 100%;
        opacity: 0.7;
        position: absolute;
    }
    
    .waiting-loader-fan {
        opacity: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
