@import "src/styles/variables";

.custom-table {
  table {
    .MuiTableCell-root {
      padding: 0 16px;
    }
    .MuiTableCell-head {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #5C6C7F;
      position: relative;
      span:first-child {
        width: 100%;
      }
      svg:hover {
        fill: #FF5722;

      }
      .MuiSvgIcon-root {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 4px;
      }

      .MuiCheckbox-root .MuiSvgIcon-root {
        font-size: 1.5rem;
        width: 1em;
        height: 1em;
        padding: 0;
        color: #5C6C7F;
      }

      .Mui-checked .MuiSvgIcon-root {
        color: #FF5722;
      }

      .MuiTableSortLabel-icon {
        path {
          width: 20px;
          height: 20px;
        }
      }
      &:hover {
        color: #5C6C7F;
        &::before {
          content: '';
          position: absolute;
          right: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background: #D6D6D6;
        }
        &::after {
          content: '';
          position: absolute;
          left: 8px;
          width: calc(100% - 16px);
          height: 2px;
          background: #FF5722;
        }
      }
    }
    .MuiTableSortLabel-iconDirectionAsc {
      transform: rotate(0);
    }
    .MuiTableSortLabel-iconDirectionDesc {
      transform: rotate(180deg);
    }
    .MuiTableSortLabel-active {
      color: #5C6C7F;
      border-color: #FF5722;
      svg {
        fill: #FF5722;
      }
    }
    [class^="MuiSvgIcon-root MTableFilterButton-filterIconFilled-"] {
      fill: #FF5722;
    }
    [class^="MTableFilterButton-filterIconWrapper-"] {
      width: 24px;
      height: 30px;
    }
    .MuiTableSortLabel-root {
      transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      div {
        width: 100%;
      }
      svg {
        opacity: 0;
      }
      &:hover {
        & svg {
          opacity: 1;
        }
      }
      &.direction{
        &-asc{
          svg {
            transform: rotate(180deg);
          }
        }
        &-desc{
          svg {
            transform: rotate(0deg);
          }
        }
      }
    }
    .MuiButtonBase-root div:hover {
      svg {
        fill: #FF5722;
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        &:hover {
          background: #E5E5E5;
          border-bottom: 1px solid #D6D6D6;
        }
      }
    }
    .MuiTableCell-body {
      color: #343434;
      span {
        line-height: 23px;
      }
    }
    .MuiTableSortLabel-root:hover {
      color: #5C6C7F;
    }
    tr {
      td {
        .MuiCheckbox-root {
          padding: 0 9px;
        }
        .deviation-cell {
          text-align: right;
          width: max-content;
          &:after {
            content: '%';
          }
          &.positive {
            &:before{
              content: "";
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              margin-right: 5px;
              display: inline-block;
              border-bottom: 8px solid green;
            }
            color: green;
          }
          &.negative {
            &:before{
              content: "";
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              margin-right: 5px;
              display: inline-block;
              border-top: 8px solid red;
            }
            color: red;
          }
        }
      }
    }
    tfoot {
      justify-content: center;
      padding-top: 17px;
    }
    tfoot tr.MuiTableRow-footer {
      .MuiTablePagination-toolbar {
        height: 35px;
        min-height: 35px;
      }
      button.MuiIconButton-root {
        padding: 4px;
        margin: 0 35px;
      }
      span:hover {
        button {
          color: #FF5722;
        }
      }
    }
  }
  &.white-table {
    .MuiPaper-root {
      background-color: $neutral-white-color;
      box-shadow: none;
    }
    .MuiTableCell-head {
      background-color: $neutral-white-color;
    }
    .MuiTableRow-footer {
      background-color: $neutral-white-color;
    }
    .MuiTableRow-root {
      background-color: $neutral-white-color;
    }
  }
  &.gray-table {
    .MuiPaper-root {
      background-color: $neutral-background-color;
      box-shadow: none;
    }
    .MuiTableCell-head {
      background-color: $neutral-background-color;
    }
    .MuiTableRow-footer {
      background-color: $neutral-background-color;
    }
    .MuiTableRow-root {
      background-color: $neutral-background-color;
    }
  }
}
