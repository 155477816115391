.main-tab-list-container .MuiMenu-paper{
  border-radius: 0 0 5px 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  background-color: #ECECEC;

  & .MuiList-padding {
    padding: 0;
  }
}

.tab-list-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  overflow: hidden;

  background-color: white;

  .active-item {
    color: #FF5722;
  }

  &:hover {
    background-color: #E5E5E5;
  }
}

.tab-list-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 40px 8px 16px;
  position: relative;

  &:hover {
    color: #343434;
  }
}

.tab-list-item-name {
  align-items: center;
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-item-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
