.problem-tab-label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .problem-count-container {
        margin-left: 8px;
        width: 22px;
        height: 22px;
        background: white;
        border-radius: 15px;
        justify-content: center;
        align-items: center;
        display: flex;
    }

}
