.balloon-root {
  width: 284px;;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  border-radius: 5px;
  background-color: #212121;
  text-align: left;
  color: white;
  z-index: 10;
  padding: 12px 16px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  cursor: default;
}
.balloon{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.balloon-body {
  width: 100%;
  height: 100%;
}

.balloon-pin {
  height: 16px;
  width: 16px;
  position: absolute;
  background-color: #212121;
  z-index: -1;
  bottom: -5px;
  transform: rotate(45deg);
}
