.main-container {
  width: 100%;
}

.opened {
  font-weight: bold;
}

.item {
  list-style-type: none;
}

.items-list {
  padding-inline-start: 10px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-left: 28px;
  border-left: 0.5px dashed transparent;
  .item {
    list-style-type: none;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  .last-item {
    width: 100%;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .last-item__text {
      margin-right: 5px;
      list-style-type: none;
      word-wrap: normal;
      white-space: break-spaces;
      padding-bottom: 5px;
      display: flex;
      flex-direction: column;
    }
  }
}

.item-list-opened {
  border-left: 0.5px dashed #616161;
}

.elem-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -10px;

  &:hover {
    color: #FF5722;
    cursor: pointer;
    .MuiSvgIcon-root.tree-icon {
      fill: #212121;
      path {
        stroke: #FF5722;
      }
    }
    .MuiSvgIcon-root.arrow-limit-close {
      fill: #FF5722;
      path {
        stroke: #FF5722;
      }
    }
  }
}

.MuiSvgIcon-root.tree-icon {
  fill: #212121;
  path {
    stroke: white;
  }
}

.MuiSvgIcon-root.arrow-limit-close {
  fill: white;
}
